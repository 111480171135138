.product-table {
    .features {
        h5 {
            text-align: center;
        }
        .content {
            width: fit-content;
            margin: 0 auto;

            .points {
                display: flex;
                align-items: center;
                word-wrap: break-word;

                p {
                    margin: 0;
                }

                margin-top: 15px;
                margin-bottom: 15px;
            }
        }
    }
    .product-img-wrapper {

        height: 200px;

        text-align: center;

        img {
            max-height: 100%;
            width: auto;
        }

        @media (max-width: 360px) {
            height: unset;

            img {
                height: auto;
                max-height: unset;
                max-width: 100%;
            }
        }
    }

    .product-table-card-panel-wrapper {
        margin: 7px;
    }
}