.services-gallery {
    .services-gallery-paged, .services-gallery-paged-d {
        .services-gallery-paged-child {
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        // .services-gallery-paged-child {
        //     // h5 {
        //     //     position: relative;
        //     //     top: 70%;
        //     //     transform: translateY(-70%);

        //     //     word-wrap: break-word;
        //     //     word-break: break-all;
        //     // }
        // }
    }
}