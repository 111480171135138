.animated-card {
    padding: 5px;
}

.animated-card > .card-panel {
    background-image: linear-gradient(to top, #D81324 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: top;

    padding: 10px;

    h6 {
        color: #0B2154;
    }

    &:hover {
        background-position: bottom;
        transition: background-position 0.9s ease;

        * {
            color: #ffffff !important;
            transition: color 1s ease;
        }
    }

    .material-icons, .fa-icons {
        font-size: 60px;
    }
}