.fullscreen-photo-gallery-wrapper {
    .fullscreen-photo-gallery {

        .photo-gallery-wrapper {
            padding: 0 30px 25px 30px;

            .photo-gallery-close-button {
                position: absolute;
        
                // right: 20vw;
                // top: 16vh;
                top: 1.7vh;
                right: 5vw;
        
                border-radius: 0;
                background-color: rgba($color: #000000, $alpha: 0);
                color: #D81324;
                padding: 0;

                @media (max-width: 767px) {
                    // right: 10vw;
                    right: 0vw;
                }
            }

            .photo-gallery-button {
                button {
                    background-color: rgba($color: #000000, $alpha: 0);
                    color: #D81324;
                    .material-icons {
                        @media (min-width: 767px) {
                            font-size: 44px;
                        }
                    }
                }
            }

            .photo-gallery-paged {
                // height: 100vh;
                height: var(--fullscreen-photo-gallery-height);

                .photo-gallery-paged-child {
                    height: 80%;
                }
            }

            width: 90%;
            margin: 0 auto;

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    width: 100vw;
    // height: 100vh;
    height: var(--fullscreen-photo-gallery-height);
    position: fixed;

    top: 0;
    left: 0;

    z-index: 999; // popup form takes first priority

    background-color: rgba($color: #000000, $alpha: 0.7);
}
