.right {
    margin-left: auto;
    margin-right: 0;
}

.left {
    margin-left: 0;
    margin-right: auto;
}

.center {
    margin-left: auto;
    margin-right: auto;
}