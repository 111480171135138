/* teko-300 - latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
         url('./fonts/teko-v10-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/teko-v10-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* teko-regular - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('./fonts/teko-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/teko-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* teko-500 - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('./fonts/teko-v10-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/teko-v10-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* teko-600 - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('./fonts/teko-v10-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/teko-v10-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* teko-700 - latin */
  @font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('./fonts/teko-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/teko-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  