.gallery-box {

    overflow: hidden;
    // cursor: pointer;

    img {
        display: block;
        object-fit: contain;
        
        max-width: 80%;
        height: auto;
        margin: 0 auto;
    }

    .gallery-box-overlay-outer {
        position: relative;
        transform: translateY(-100%);

        max-width: 80%;
        margin: 0 auto;
    }

    &:hover {
        .gallery-box-overlay {
            opacity: 0.7;
            transform: scale(0.9);

            transition: opacity 700ms ease, transform 500ms ease 200ms;

            position: relative;
        }

        .gallery-box-text-section {

            position: relative;
            transform: translateY(-130%);
            top: 30%;
            z-index: 2;
    
            margin: 2em;

            opacity: 1;
            transition: opacity 700ms ease, transform 500ms ease 200ms;

            .gallery-box-text-section-inner {
                position: relative;
                top: 30%;
                transform: translateY(-50%);

                .button {
                    @media (max-width: 360px) {
                        font-size: 11px;
                        line-height: unset;
                        min-width: 3rem;
                        min-height: 1.5rem;
                    }
                }
            }
        }
    }

    .gallery-box-overlay {
        height: 303px;

        position: relative;
        // transform: translateY(-303px);
        z-index: 1;

        background-color: #D81324;
        opacity: 0;
    }

    .gallery-box-text-section {
        opacity: 0;
    }

    h5 {
        @media (max-width: 360px) {
            font-size: 13px;
            margin: 0;
        }
    }
}