.choose-us-text {
    width: 80%;

    .choose-us-text-bar-wrapper {
        background-color: rgba($color:  #666666, $alpha: 0.2);
        .choose-us-text-bar {
            height: 15px;
            width: 0;
    
            background-color: #D81324;
        }
    }
}

.choose-us-img-wrapper {

    .choose-us-img-overlay {
        background-color: transparent;
        background-image: linear-gradient(290deg, #D81324 45%, #FFFFFF00 45%);

        width: 80%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0 auto;
        padding: 5px;
    }
    
    .choose-us-img {
        width: 95%;
        img {
            width: 100%;
            height: unset;
        }
        
    }
}