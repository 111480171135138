.social {
    max-width: 1140px;

    .gmap-iframe-div {
        min-width: 100%;
        min-height: 600px;
    }
    
    .gmap-iframe {
        min-width: 100%;
        min-height: 600px;
    }

    .fb-iframe-div {
        min-width: 100%;
        min-height: 600px;
    }

    & > div {
        padding: 19px;
    }

    @media (min-width: 500px) and (max-width: 767px) {
        .gmap-iframe-div {
            min-width: 500px;
        }
        
        .gmap-iframe {
            min-width: 500px;
        }

        & > div {
            padding: 0;
        }
    }
}
