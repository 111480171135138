.scroll-to {
    position: fixed;
    // right: -67px;
    // top: calc(50% + 82px);
    // transform: rotateZ(270deg) translateX(50%);

    // transform: translateX(50%);
    right: 20px;
    z-index: 3;

    @media (min-width: 768px) {
        top: 164px;
    }
    @media (max-width: 767px) {
        top: 86px;
    }

    button {
        // border-bottom-right-radius: 0;
        // border-bottom-left-radius: 0;
        border-radius: 0;

        transform: scale(1.3);

        background-color: #D81324;
        color: #fff;
        // border: 2px solid #D81324;

        // animation: scroll-to-btn-anim .9s ease-in-out infinite alternate;

        &:focus {
            box-shadow: none !important;
            border-color: none !important;
        }
        &:active {
            box-shadow: none !important;
            border-color: none !important;
        }
    }

}

// @keyframes scroll-to-btn-anim {
//     from {border-color: none;}
//     to {border-color: #1A237E;}
// }