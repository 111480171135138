.brand-logo-section {
    margin-top: 70px;

    background-color: rgba($color: #ECEFF1, $alpha: 1);
}

.brand-logo-section-title {
    h5 {
        font-family: Teko;
        font-weight: 400;
        font-size: 26px;
        letter-spacing: 0px;
        margin: 14px 0;
    }
}

.brand-logo-section-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}