.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="105px"]::before {
    content: '105px';
    font-size: 105px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="66px"]::before {
    content: '66px';
    font-size: 66px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="52px"]::before {
    content: '52px';
    font-size: 52px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="37px"]::before {
    content: '37px';
    font-size: 37px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
    content: '26px';
    font-size: 26px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: '22px';
    font-size: 22px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: '16px';
    font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
    content: '15px';
    font-size: 15px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: '14px';
    font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: '12px';
    font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: '10px';
    font-size: 10px !important;
}