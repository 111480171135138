.brand-logo {
    width: 10%;
    margin: 13px 0 13px 0;
    box-sizing: border-box;

    .brand-logo-img {
        width: 70px;
        height: auto;
    
        display: block;
        object-fit: contain;
        margin: 0 auto;
    }
    
    .brand-logo-link{
        span {
            display: block;
        }
    }

    a {
        font-weight: 600;
        color: #666666;
    }
}

@media (max-width: 991px) {
    .brand-logo {
        width: 25%;
        box-sizing: border-box;

        .brand-logo-img {
            width: 70px;
            height: auto;
        }
    }
}

@media (max-width: 400px) {
    .brand-logo {
        width: 33%;
        box-sizing: border-box;

        .brand-logo-img {
            width: 50px;
            height: auto;
        }
    }
}