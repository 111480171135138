@media (min-width: 768px) and (max-width: 991px) {
    .contact-form-quote {
        position: relative;
        transform: translateY(-120px);
    }
}

@media (min-width: 992px) {
    .contact-form-quote {
        position: relative;
        transform: translateY(-120px);
    }
}

.contact-form {
    .col-wrapper-7 {
        align-items: unset;
    }
}

.contact-form-exp-cards {
    width: 80%;
}

.contact-form-exp-title {
    color: #D81324;
    font-weight: 600;
    letter-spacing: 1.3px;
}

.contact-form-exp, .contact-form-quote {
    width: 80%;
}

.contact-form-quote {
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
}

.contact-form-quote-title {
    background-color: #D81324;

    h4 {
        padding: 1.5rem;
    }
}

.contact-form-quote-form {
    padding: 0.5rem;

    .input-div {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    button {
        width: 100%;
        height: 58px;

        background-color: #D81324;
        color: #ffffff;

        font-size: 18px;
        border-radius: 0;
    
        margin: 0;
    }

    .contact-form-quote-input-textarea {
        padding-bottom: 3.62rem;
    }

    textarea {
        min-height: 6rem;
    }

    div:not(:last-of-type):not(.contact-form-error-div):not(.contact-form-notified-div) {
        border: 1px solid rgba($color: #E7EBF0, $alpha: 1) ;
    }

    .contact-form-error-div{
        p {
            color: #D50000;
        }
    }
}

.contact-form-big-yt {
    width: 100%;
    // height: 550px;

    margin: 30px auto 30px auto;

    iframe {
        width: 100%;
        // height: 550px;
    }
}