.blog-card {

    width: 80%;

    .blog-card-img {
        overflow: hidden;
        img {
            width: 100%; height: auto;
        }
    
        &:hover {
            img {
                position: relative;
                transform: scale(1.1) rotate(1deg);
                opacity: 0.8;

                transition: transform 500ms ease, opacity 500ms ease;
            }
        }
    }

    .blog-card-date {

        background-color: #D81324;

        width: 45px;
        padding: 5px;

        position: absolute;
        transform: translate(-27px, -100px);

        p {
            color: #fff;
            margin: 0;
        }

        .blog-month {
            font-size: 12px;
            font-weight: 400;
        }
        
        .blog-year {
            font-size: 20px;
            font-weight: 600;
        }

        .blog-card-date-triangle {
            width: 0;
            height: 0;
            border-bottom: 12px solid rgba($color: #860610, $alpha: 1);
            border-left: 12px solid transparent;

            position: absolute;
            transform: translate(-5px, -17px);
        }
    }

    .material-icons {
        display:inline-flex;
        vertical-align:middle;
    }

    a {
        font-family: "Teko", Sans-serif;
        font-size: 16px;
        font-weight: 600;
    }

    .blog-card-text {
        p {
            color: rgba($color: #666666, $alpha: 1);
    
            &:nth-of-type(2) {
                &:hover {
                    position: relative;
                    transform: translateY(-5px);
                    transition: transform 500ms ease;
                }
            }
        }
    }

}