@mixin background-prop {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;        
}

.premium-service {
    // height: 462px;

    background: url("../../../Assets/Background/CarFixing/Images/car-fixing-1.jpg");
    @include background-prop();

    @media (max-width: 360px) {
        .get-started-btn {
            width: 175px;
            height: 58px;
            font-size: 18px;
        }
    }

    .get-started-btn {
        background-color: #D81324;
        color: #ffffff;
        width: 175px;
        height: 58px;
        font-size: 18px;
        border-radius: 0;
        text-align: center;
    
        margin: 0;
    
        &:hover {
            position: relative;
            transform: translateY(-10px);
    
            transition: transform 300ms ease;
        }

        span {
            position: relative;
            top: 50%; transform: translateY(-50%);
        }
    }
}

.premium-service-overlay {
    background-image: linear-gradient(300deg, #000000BD 83%, #D81324 60%);

    @media (min-width: 992px) {
        h2 {
            font-size: 48px;
            font-weight: 600;
    
            width: 70%;

            padding-top: 50px;
        }

        p {
            width: 55%;
        }

        div {
            padding-bottom: 50px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        h2 {
            font-size: 36px;
            font-weight: 500;

            padding-top: 50px;
    
            width: 65%;
        }

        p {
            width: 55%;
        }

        div {
            padding-bottom: 50px;
        }
    }

    @media (max-width: 767px) {
        h2 {
            font-size: 36px;
            font-weight: 500;
            padding: 50px 15px 0px 15px;
        }

        p {
            padding: 0 15px;
        }

        div {
            padding-bottom: 50px;
        }
    }
}