.footer-newsletter {
    .footer-newsletter-heading {
        margin: 0;
        margin-top: 15px;
    }
    .card-panel {
        p {
            color: #7A7A7A;
        }
    }

    position: relative;
    bottom: 120px;
}

.footer-newsletter-form {
    display: flex;
}

.footer-newsletter-input {
    width: 100%;
}

.footer-newsletter-send-btn {
    background-color: #D81324;
    color: white;   

    width: 100%;
    height: 81%;
}

.footer-links-wrapper {
    position: relative;
    bottom: 170px;
    margin-bottom: -170px;
}

.footer-1-logo {
    width: 200px;
    height: auto;
}

@media (max-width: 767px) {
    .footer-1-logo {
        width: 200px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-1-logo {
        width: 200px;     
    }

    .footer-1-logo-div {
        text-align: left;    
    }
}

.footer-bg {
    background-image: url("../../Assets/Background/CarEngine/Images/car-engine-1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    & p, h2 {
        color: white;
    }

    & h2 {
        padding-left: 7px;
        font-weight: 600;
        font-size: 26px;
        letter-spacing: 1.3px;
    }
}

.footer-col-wrapper {
    max-width: 1140px;
    margin: 0 auto;

    padding: 17px;

    align-items: unset;

    & > div:nth-child(2) {
        border-bottom: 1px solid #A0AABA73;
    }
}

.footer-overlay {
    background-color: rgba($color: #000000, $alpha: 0.85);
}

.footer-1 {
    font-size: 16px;

    margin-top: 45px;
}

.footer-1-icon-phone{
    padding-left: 15px;
}

.footer-1-icon-para-location {
    padding-left: 16px;

    p {
        margin: 0;
    }
}

.footer-1-icon-para-div {
    display: flex;
    align-items: center;
}

.footer-2-para-div {
    p {
        & > * {
            display: flex;
            align-items: center;
        }

        & > a:hover {
            span {
                color: #D81324 !important;
            }
        }
    }
}

.footer-3 {
    margin-top: -12px;
}

.footer-3-div-schedule {
    display: flex;

    p {
        align-self: center;

        &.footer-3-para-schedule {
            position: relative;
            top: -2px;
            padding-left: 12px;

            font-size: 16px;
            font-weight: 400;
        }
    }
}

.footer-3-para-query {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.footer-3-content-div {
    padding-left: 6px;
    margin-bottom: 9px;

    position: relative;
    top: -12px;
}

.footer-3-call-button {
    border-radius: 0;

    background-color: #D81324;
    color: #ffffff;
    padding: 10px 30px;
}

.footer-notes {
    height: 35px;
}

.footer-dummy {
    display: block;
    height: 152px;
}