.logoSvgTwo {
    position: absolute;
    display: block;
}

#logoSvgTwoC {
    animation-name: rotateAnim;
    animation-duration: 1300ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 50% 50%;

    width: 125px;
    height: 125px;
}

#logoSvgTwoM {
    position: relative;
    transform: translate(3px, 17px);

    width: 100px;
}

@keyframes rotateAnim {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.logo-svg-container {
    &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;
        min-width: 100vw;
    }
}