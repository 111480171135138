.paged {
    display: flex;
    overflow: hidden;
    
    & > div {
        min-width: 100%;
        align-self: center;
        transition: transform 300ms ease-in-out, visibility 300ms;

        position: relative;

        padding-left: 5px;
        padding-right: 5px;
    }
}