.testimonial-wrapper {

    .testimonial-card {

        // width: 80%;
        height: 100%;
    
        .testimonial-card-img {
            overflow: hidden;
    
            height: 300px;

            img {
                // width: 100%;
                display: block;
                width: auto;
                height: 100%;
                object-fit: contain;
                margin: 0 auto;
            }
        
            &:hover {
                img {
                    position: relative;
                    transform: scale(1.1) rotate(1deg);
                    opacity: 0.8;
    
                    transition: transform 500ms ease, opacity 500ms ease;
                }
            }
        }
    
        .material-icons {
            display:inline-flex;
            vertical-align:middle;
        }
    
        a {
            font-family: "Teko", Sans-serif;
            font-size: 16px;
            font-weight: 600;
        }
    
        .testimonial-card-text {
            p {
                color: rgba($color: #666666, $alpha: 1);
            }
        }
    }

    .testimonial {

        max-width: 100vw;

        .testimonial-button-wrapper {
            
            position: relative;
    
            transform: translateY(278px);
    
            z-index: 1;
    
            .testimonial-button {
                position: absolute;
            
                button {
                    border-radius: 0;
                    background-color: #D81324;
                    color: #FFFFFF;
    
                    min-width: unset;
                    padding: 0;
                }
    
                &.testimonial-button-2 {
                    right: 0;
                }
            }
        }

        .testimonial-paged {
            padding-bottom: 20px;

            @media (min-width: 992px) and (max-width: 100vw) {
                & div {
                    min-width: 33%;
                }
            }

            // tab
            @media (min-width: 768px) and (max-width: 991px) {
                & div {
                    min-width: 50%;
                }
            }

            // mb
            @media (max-width: 767px) {
                & div {
                    min-width: 100%;
                }
            }
            > div {
                align-self: baseline;
            }
        }
    }
}

