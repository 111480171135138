.photo-gallery {
    .photo-gallery-button-wrapper {
            
        position: relative;

        transform: translateY(50vh);
        z-index: 1;

        .photo-gallery-button {
            position: absolute;
        
            button {
                border-radius: 0;
                background-color: #D81324;
                color: #FFFFFF;

                min-width: unset;
                padding: 0;
            }

            &.photo-gallery-button-2 {
                right: 0;
            }
        }
    }
    
    .photo-gallery-paged {
        & > div {           
            height: 300px;            
        }

        @media (min-width: 0px) and (max-width: 480px) {
            & > div {
                height: 200px;            
            }
        }

        .photo-gallery-paged-child {
            h5 {
                position: absolute;
                top: 70%;
                transform: translateY(-70%);

                word-wrap: break-word;
                word-break: break-all;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}