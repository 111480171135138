//Fonts settings are for Heebo and Teko font only

%h1{ font-family: unquote("Teko"); font-weight: 300; font-size: 105px; letter-spacing: -1.5px; margin: 14px 0; }
%h2{ font-family: unquote("Teko"); font-weight: 300; font-size: 66px; letter-spacing: -0.5px; margin: 14px 0; }
%h3{ font-family: unquote("Teko"); font-weight: 400; font-size: 52px; letter-spacing: 0px; margin: 14px 0; }
%h4{ font-family: unquote("Teko"); font-weight: 400; font-size: 37px; letter-spacing: 0.25px; margin: 14px 0; }
%h5{ font-family: unquote("Teko"); font-weight: 400; font-size: 26px; letter-spacing: 0px; margin: 14px 0; }
%h6{ font-family: unquote("Teko"); font-weight: 500; font-size: 22px; letter-spacing: 0.15px; margin: 14px 0; }

%body1{ font-family: unquote("Heebo"); font-weight: 400; font-size: 16px; letter-spacing: 0.5px; }
%body2{ font-family: unquote("Heebo"); font-weight: 400; font-size: 14px; letter-spacing: 0.25px; }

%subtitle1{ font-family: unquote("Heebo"); font-weight: 400; font-size: 16px; letter-spacing: 0.15px; }
%subtitle2{ font-family: unquote("Heebo"); font-weight: 500; font-size: 14px; letter-spacing: 0.1px; }

%button{ font-family: unquote("Teko"); font-weight: 500; font-size: 15px; letter-spacing: 1.25px; }

%overline{ font-family: unquote("Heebo"); font-weight: 400; font-size: 10px; letter-spacing: 1.5px; }
// %overline{ font-family: unquote("Teko"); font-weight: 400; font-size: 11px; letter-spacing: 1.5px; }

%caption{ font-family: unquote("Heebo"); font-weight: 400; font-size: 12px; letter-spacing: 0.4px; }
// %caption{ font-family: unquote("Teko"); font-weight: 400; font-size: 13px; letter-spacing: 0.4px; }

%label{ font-family: unquote("Heebo"); font-weight: 400; font-size: 14px; letter-spacing: 0.25px; }
// %label{ font-family: unquote("Teko"); font-weight: 400; font-size: 14px; letter-spacing: 0.25px; }

%global-default { @extend %body1; }
// %global-default { @extend %body1; }