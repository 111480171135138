.popup-form-div-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;

    z-index: 9999; // popup form takes first priority

    background-color: rgba($color: #000000, $alpha: 0.5);
}

.popup-form-msg-div-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;

    z-index: 99999; // popup form takes first priority

    background-color: rgba($color: #000000, $alpha: 0.5);

    .card-panel {
        width: 90vw;
        @media (min-width: 992px) {
            // width: 70vw;
        }
        max-height: 81vh;
        overflow-x: hidden;

        margin-left: auto;
        margin-right: auto;

        // desktop
        @media (min-width: 992px) {
            width: 992px;

            margin-left: auto;
            margin-right: auto;
        }

        .secondary-color {
            color: #0B2154;
        }
        .primary-color {
            color: #D81324;
        }
    }
}

.popup-form-div {

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  
    // background-image: url("../../../Assets/Background/CeramicCoating//Images/MercedesBenzGLBlack/10.jpg");
    background-image: linear-gradient(45deg, #D81324 45%, #FFFFFF00 45%);
    padding: 0;

    .popup-form-div-inner {
        // background-color: rgba(0, 0, 0, 0.6);
        padding-bottom: 50px;
    }

    width: 90vw;
    @media (min-width: 992px) {
        // width: 70vw;
    }
    max-height: 81vh;
    overflow-x: hidden;

    margin-left: auto;
    margin-right: auto;

    // desktop
    @media (min-width: 992px) {
        width: 992px;

        margin-left: auto;
        margin-right: auto;
    }

    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);

    position: relative;

    .popup-form-info-card {
        margin: 0 auto;
    }

    h4 {
        font-weight: 600;
        text-align: center;
    }

    h6 {
        color: #0B2154;
        text-align: center;
    }

    .popup-form-div-close {
        margin: 0 0 0 auto;
        width: fit-content;
    }

    .popup-form-form {
        padding: 0.5rem;
    
        max-width: 400px;
        margin: 0 auto;
    
        .input-div {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    
        button {
            width: 100%;
            height: 58px;
    
            background-color: #D81324;
            color: #ffffff;
    
            font-size: 18px;
            border-radius: 0;
        
            margin: 0;
        }
    
        .popup-form-input-textarea {
            padding-bottom: 3.62rem;
        }
    
        textarea {
            min-height: 6rem;
        }
    
        div:not(:last-of-type):not(.popup-form-error-div):not(.popup-form-notified-div) {
            border: 1px solid rgba($color: #E7EBF0, $alpha: 1) ;
        }

        .popup-form-error-div{
            p {
                color: #D50000;
            }
        }
    }
}