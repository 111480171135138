@use 'Grid/Grid.scss';
@use 'Fonts/Fonts.scss';
@use 'Input/Input.scss';
@use 'Color/Color.scss';
// @use 'Shadow/Shadow.scss';
@use 'Typography/Typography.scss';
@use 'Card/Card.scss';
@use 'Divider/Divider.scss';
@use 'Visibility/Visibility.scss';
@use 'Anchor/Anchor.scss';
@use 'Stepper/Stepper.scss';
@use 'Header/Header.scss';
@use 'PagedDiv/PagedDiv.scss';
@use 'BreathingDiv/BreathingDiv.scss';
@use 'Preloader/Preloader.scss';
@use 'Helper/Helper.scss';
@use 'JSXModal/JSXModal.scss';

*, *::before, *::after {
    box-sizing: border-box;
}

body,html {
    overflow-x: hidden;
}