@use '../../Color/Color' as _Color;
@use '../../Shadow/Shadow';
@use '../../Typography/Typography';

%label-anim {
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}
%icon-anim {
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

%label-moved-up {
    position: relative;
    transform: translate(0.5rem, -3rem);
    @extend %label-anim;

    font-size: 0.8rem;
    @extend .sub2;
}

%label-moved-up-when-icon-used {
    position: relative;
    transform: translate(0.5rem, -3rem);
    @extend %label-anim;

    font-size: 0.8rem;
    @extend .sub2;
}

%label-default-pos {
    position: relative;
    transform: translate(1.2rem, -2.1rem);
    @extend %label-anim;
}

%label-default-pos-when-icon-used {
    position: relative;
    transform: translate(2rem, -2.1rem);
    @extend %label-anim;
}

%icon-default-pos {
    position: absolute;
    padding: 0 5px;
    @extend %icon-anim;
}

%icon-moved-down {
    transform: translateY(0.35rem);
}

%input-text {
    outline: 0;

    padding-left: 1rem;
    padding-right: 1rem;
    border-width: 0 0 2px;
    border-radius: 2px;

    width: 100%;
    min-height: 2.4rem;

    @extend .default-border-color;
    @extend .body2 !optional;
    
    background-color: transparent;

    box-shadow: none;
}

%input-text-when-icon-used {
    padding-left: 2rem;
}

//if you modify the below line, you should also modify $input-text-type-list in Color.scss for specificity reasons
$input-type-list: (text, email, password, number);

.input-div {

    padding: 12px 0px 0px 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    
    @extend .default-bg-color;
    
    display: grid;
    grid-template-columns: repeat(1, 12fr);
    grid-template-rows: 2.4rem 0rem;

    textarea {
        @extend %input-text;

        padding-top: 0.5rem;
        padding-bottom: 0.1rem;
        // height: 10px;
        resize: none;
        &:focus {
            &:required {
                &:invalid {
                    @extend .invalid-border-color;
                    // &+label {
                    //     @extend .invalid-color;
                    // }
                }
            }
        }
        &:required {
            &:valid {
                @extend .valid-border-color;
            }
        }
        &+label {
            @extend .default-color;
            @extend %label-default-pos;
        }
        &:focus, &:not(:placeholder-shown) {
            &+label {
                @extend %label-moved-up;
            }
        }

        //remove autofill background color
        &:-webkit-autofill {
            -webkit-background-clip: text;
            background-clip: text;
        }
    }

    &.icon, .material-icons {
        line-height: unset;
        textarea {
            @extend %input-text-when-icon-used;
            &+label {
                @extend %label-default-pos-when-icon-used;
            }
            &:focus, &:not(:placeholder-shown) {
                &+label {
                    @extend %label-moved-up;
                }
                &+label+.material-icons {
                    @extend %icon-moved-down;
                }
            }
            &+label+.material-icons {
                @extend %icon-default-pos;
            }
        }
    }

    @each $input-type in $input-type-list {
        input[type="#{$input-type}"] {
            @extend %input-text;
            &:focus {
                &:required {
                    &:invalid {
                        @extend .invalid-border-color;
                        // &+label {
                        //     @extend .invalid-color;
                        // }
                    }
                }
            }
            &:required {
                &:valid {
                    @extend .valid-border-color;
                }
            }
            &+label {
                @extend .default-color;
                @extend %label-default-pos;
            }
            &:focus, &:not(:placeholder-shown) {
                &+label {
                    @extend %label-moved-up;
                }
            }
            
            //hide spin buttons for number field
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            //remove autofill background color
            &:-webkit-autofill {
                -webkit-background-clip: text;
                background-clip: text;
            }
        }
        &.icon, .material-icons {
            line-height: unset;
            input[type="#{$input-type}"] {
                @extend %input-text-when-icon-used;
                &+label {
                    @extend %label-default-pos-when-icon-used;
                }
                &:focus, &:not(:placeholder-shown) {
                    &+label {
                        @extend %label-moved-up;
                    }
                    &+label+.material-icons {
                        @extend %icon-moved-down;
                    }
                }
                &+label+.material-icons {
                    @extend %icon-default-pos;
                }
            }
        }
    }   
}