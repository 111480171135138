.youtube-embed {

    display: flex;
    align-items: center;
    justify-content: center;

    .iframe {
        width: fit-content;
    }

    // @media (max-width: 510px) {
    //     iframe {
    //         height: 228px;
    //     }
    // }

    // @media (max-width: 296px) {
    //     iframe {
    //         height: 140px;
    //     }
    // }
    // iframe {
    //     height: 300px;
    // }
    @media (min-width: 992px) {
        iframe {
            // width: 992px !important;
            height: 551px;
        }
    }
}