/* heebo-100 - latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-200 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-300 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-regular - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-500 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-600 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-800 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-900 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* heebo-700 - latin */
  @font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('./fonts/heebo-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/heebo-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  