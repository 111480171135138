.services-box {

    overflow: hidden;

    img {
        display: block;
        object-fit: contain;
        width: 100%;
        height: auto;
    }

    .services-box-overlay-wrapper {
        transform: translateY(-100%);
    }

    &:hover {
        .services-box-overlay {
            opacity: 0.7;
            transform: scale(0.9);
            transition: opacity 700ms ease, transform 500ms ease 200ms;
        }

        .services-box-text-section {
            position: relative;

            transform: translateY(-130%);
            top: 50%;
            z-index: 2;
    
            margin: 2em;

            opacity: 1;
            transition: opacity 700ms ease, transform 500ms ease 200ms;

            @media (max-width: 426px) {
                transform: translateY(-150%);
                h5 {
                    font-size: 1.1em;
                }

                p {
                    font-size: 0.9em;
                }
            }
        }
    }

    .services-box-overlay {
        height: 100%;

        position: relative;
        z-index: 1;

        background-color: #D81324;
        opacity: 0;
    }

    .services-box-text-section {
        height: 100%;
        opacity: 0;
    }
}