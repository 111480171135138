.page-location {
    height: 350px;

    img {
        display: block;
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
    }

    .page-location-content-outer {
        transform: translateY(-100%);
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        h4 {
            font-size: 42px;
            font-weight: 600;
            letter-spacing: 1.3px;
        }

        p {
            margin: 0;
        }

        .material-icons {
            vertical-align: bottom;
        }
    }
}