.header {
    // max-width: 1140px;
    margin: 0 auto;
}

.header-1 {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #E7EBF0;
    transition: border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.header-logo {
    width: 190px;
    height: auto;
    @media (min-width: 992px) and (max-width: 1140px) {
        width: 143px;
    }
}

.header-1-contact {
    white-space: pre-wrap;
    display: flex;

    // justify-content: right;
    width: fit-content;
    margin: 0 0 0 auto;
}

.header-contact-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;

    margin-left: 15px;

    .header-contact-icon {
        font-size: 14px;
        color: #ffffff;
    }

    .header-contact-title {
        font-size: 18px;
        margin: 0;
        color: #ffffff;
    }

    .header-contact-content {
        margin: 0;
        color: #ffffff;
    }
}

.header-location-wrapper {
    display: flex;
    align-items: baseline;
    width: fit-content;

    margin-left: 15px;

    .header-location-title-wrapper {
        margin-right: 10px;
    }

    .header-location-icon {
        font-size: 14px;
        color: #ffffff;
    }

    .header-location-title {
        font-size: 18px;
        margin: 0;
        color: #ffffff;
    }

    .header-location-content {
        margin: 0;
        color: #ffffff;
    }
}

@media (min-width: 1110px) {
    .header-1 {
        background-color: transparent;
        background-image: linear-gradient(300deg, #D81324 51em, #FFFFFF 0%);
    }
}

@media (min-width: 800px) and (max-width: 1109px) {
    .header-1 {
        background-color: transparent;
        background-image: linear-gradient(300deg, #D81324 51em, #FFFFFF 0%);
    }
}

@media (min-width: 768px) and (max-width: 800px) {
    .header-1 {
        background-color: transparent;
        background-image: linear-gradient(300deg, #D81324 47em, #FFFFFF 0%);
    }
}

.header-follow-us {
    display: flex;
    p{
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 auto;
    }

    svg{
        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;
    }

    a {
        &:hover {
            svg {
                color: #0B2154;
            }
        }
        &:visited {
            color: unset;
        }
    }
}

.header-2-menu-button {
    /* padding: 0; */
    min-width: auto;
    border-radius: 0;
    background-color: #D81324;
    color: #FFFFFF;

    display: block;

    margin-right: 0;
    margin-left: auto;

    &:focus {
        box-shadow: 0px 0px 2px 2px #D81324;
        border-color: #D81324;
    }

    @media (min-width: 1070px) {
        display: none;
    }
}

.header-2-links {
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
        margin-right: 15px;
    }

    .header-2-links-h6-wrapper {
        display: flex;
        align-items: center;
        justify-items: right;
        margin: 0 0 0 auto;
    }

    h6 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.5px;

        padding-right: 15px;

        & .material-icons {
            position: relative;
            top: 6px;
            display: inline-block;
            margin-top: -16px;
        }

        a {
            display: block;
            &:hover {
                color: #D81324 !important;
            }
            &:visited {
                color: #0B2154;
            }
        }

        @media (max-width: 1069px) {
            display: none;
        }
    }
}

.sidenav {
    position: absolute;
    top: 0;
    left: 0;

    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    
    width: 65vw;
    height: 100vh;

    transform: translateX(-100%);
    transition: transform 700ms ease 500ms;

    background-color: #fff;

    z-index: 5;

    overflow: scroll;
}

.dummy-sidenav {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba($color: #000000, $alpha: 0.5);

    transform: translateX(-100%);
    transition: transform 500ms ease;

    z-index: 4;
}

.header-2-links-sidenav {
    h6 {
        font-size: 16px;
        font-weight: 600;

        letter-spacing: 1.6px;

        & .material-icons {
            position: relative;
            top: 6px;
            display: inline-block;
            margin-top: -16px;
        }

        a {
            &:hover {
                color: #D81324;
            }

            &:visited {
                color: #0B2154;
            }
        }
    }

    
    & .button {
        display: block;
    }

    & .dropdown-content {
        width: 75vw;
        min-width: auto;
    }

    .header-contact-wrapper{
        margin: 15px 0;
        .header-contact-icon {
            color: #0B2154;
        }
    
        .header-contact-title {
            color: #0B2154;
        }
    
        .header-contact-content {
            color: #0B2154;
        }
    }
}

// .dropdown {
//     position: relative;
//     display: inline-block;
// }

.dropdown-content {
    visibility: hidden;
    position: fixed;
    z-index: 1;

    min-width: 220px;
    padding: 15px 25px 15px 25px;

    transform: translateY(-10px);
}

.dropdown:hover .dropdown-content {
    visibility: visible;
    transform: translateY(0%);

    transition: transform 400ms ease;
}

.header-2 {
    // col wrapper
    & > div {
        padding-top: 0;
        padding-bottom: 0;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    height: 85px;

    background-color: rgba($color: #FFFFFF, $alpha: 1);
    box-shadow: -1px 16px 15px -6px rgba(0, 0, 0, 0.10);
}

.header-sticky {
    position: fixed;
    top: 0;
    width: 100%;

    z-index: 999;
}

// main div
.main-padding {
    padding-top: 80px;

    @media (max-width: 991px) {
        padding-top: 80px;
    }
}

.header-2-wrapper {
    transition: position 500ms ease;
}