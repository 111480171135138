// .mission-and-vision-bg {
//     div {
//         width: 50%;
//         margin: 0 auto;

//         background-color: #D81324;

//         height: 220px;
//     }
// }

.mission-and-vision {
    background-color: #eceff1;
}

.mission-and-vision-content {
    .middle {
        height: 100%;

        div {
            height: 100%;
            // background-color: transparent;
            
            // background-color: transparent;
            
            background-image: linear-gradient(54deg, #eceff1 45%, #FFFFFF00 45%), linear-gradient(54deg, #D81324 55%, #FFFFFF00 55%);
        }
    }
}