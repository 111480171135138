.t-align-right {
    text-align: right;
}

.t-align-left {
    text-align: left;
}

.t-align-center {
    text-align: center;
}

.t-align-justify {
    text-align: justify;
}